<template>
  <div class="order-list-wrapper" v-loading="loading">
    <en-table-layout :tableData="pageData.data" @selection-change="orderListSelectionChange" :row-key="getRowKeys"
      ref="enTableLayout">
      <template slot="header">
        <el-tabs type="card" @tab-click="setType" v-model="activeName">
          <el-tab-pane label="卡券订单列表" name="1" />
          <el-tab-pane label="卡券退单列表" name="2" />
          <el-tab-pane label="充值订单列表" name="3" />
          <el-tab-pane label="充值退款单列表" name="4" />
        </el-tabs>
      </template>
      <template slot="toolbar">
        <el-form-item v-if="['1', '2'].includes(activeName)" label="订单创建时间">
          <el-date-picker style="width: 230px" v-model="advancedForm.order_time_range" type="daterange" align="center"
            size="medium" :editable="false" unlink-panels range-separator="-" start-placeholder="开始日期"
            end-placeholder="结束日期"></el-date-picker>
        </el-form-item>
        <el-form-item v-if="activeName === '1'" label="激活状态" class="col-auto">
          <el-select style="width: 120px" size="medium" v-model="advancedForm.status" placeholder="请选择" clearable>
            <el-option label="全部" value></el-option>
            <el-option label="未激活" value="0"></el-option>
            <el-option label="已激活" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="activeName === '2'" label="禁用状态" class="col-auto">
          <el-select style="width: 120px" size="medium" v-model="advancedForm.status" placeholder="请选择" clearable>
            <el-option label="全部" value></el-option>
            <el-option label="未禁用" value="0"></el-option>
            <el-option label="已禁用" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="单据状态" class="col-auto">
          <el-select style="width: 120px" size="medium" v-model="advancedForm.trade_status" placeholder="请选择" clearable>
            <el-option v-for="item in newtradeType" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="['3', '4'].includes(activeName)" label="单据日期">
          <el-date-picker style="width: 250px" v-model="advancedForm.order_time_range" type="daterange" align="center"
            size="medium" :editable="false" unlink-panels range-separator="-" start-placeholder="开始日期"
            end-placeholder="结束日期"></el-date-picker>
        </el-form-item>
        <el-form-item v-if="['1', '2'].includes(activeName)" label="应用类型" class="col-auto">
          <el-select style="width: 120px" size="medium" v-model="advancedForm.app_type_shop_type_flag" placeholder="请选择"
            clearable>
            <el-option v-for="item in typeOptions" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="['3', '4'].includes(activeName)" label="来源应用" class="col-auto">
          <el-select style="width: 120px" size="medium" v-model="advancedForm.shop_type" placeholder="请选择" clearable>
            <el-option label="全部" value=""></el-option>
            <el-option v-for="(item, index) in shopTypeOption" :key="index" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="单据来源" class="col-auto">
          <el-select style="width: 120px" size="medium" v-model="advancedForm.trade_source" placeholder="请选择" clearable>
            <el-option label="全部" value></el-option>
            <el-option label="企业后台" value="1"></el-option>
            <el-option label="移动端前台" value="2"></el-option>
          </el-select>
        </el-form-item>
      </template>
      <template slot="toolbar_btn">
        <el-form-item>
          <el-input size="medium" v-model="advancedForm.keywords" @input="
    advancedForm.keywords = advancedForm.keywords.replace(/\s+/g, '')
    " placeholder="请输入关键字进行搜索" clearable>
            <el-select slot="prepend" size="medium" v-model="advancedForm.type" placeholder="请选择类型"
              style="width: 130px">
              <el-option v-if="activeName === '1'" label="销售单据编号" value="sell_trade_sn"></el-option>
              <el-option v-if="activeName === '2'" label="销售退单编号" value="sell_trade_sn"></el-option>
              <el-option v-if="activeName === '3'" label="充值销售单号" value="recharge_sn"></el-option>
              <el-option v-if="activeName === '4'" label="充值退款单号" value="recharge_sn"></el-option>
              <el-option v-if="['1', '2'].includes(activeName)" label="卡券名称" value="card_name"></el-option>
              <el-option label="客户名称"
                :value="['3', '4'].includes(activeName) ? 'customer_name' : 'client_name'"></el-option>
              <el-option label="联系人名称"
                :value="['3', '4'].includes(activeName) ? 'contacts_person' : 'link_name'"></el-option>
              <el-option label="销售人员名称"
                :value="['3', '4'].includes(activeName) ? 'seller_name' : 'sell_name'"></el-option>
              <el-option label="商城名称" value="shop_name"></el-option>
              <el-option v-if="['1', '2'].includes(activeName)" label="卡号" value="cards_code"></el-option>
              <el-option v-if="['3', '4'].includes(activeName)" label="备注信息" value="remark"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="advancedSearchEvent" type="primary" size="small">搜索</el-button>
          <el-button @click="submitImport(0)" :loading="importLoading" size="small" type="primary">导出全部</el-button>
          <el-button @click="submitImport(1)" :loading="importLoading1" size="small" type="primary">导出勾选项</el-button>
        </el-form-item>
      </template>
      <template v-if="['1', '2'].includes(activeName)" slot="table-columns">
        <el-table-column key="selection" type="selection" :reserve-selection="true" fixed />
        <el-table-column :prop="activeName === '1' ? 'sell_trade_sn' : 'sell_refund_record_sn'"
          :label="activeName === '1' ? '销售单据编号' : '销售退单编号'" min-width="200" fixed="left"></el-table-column>

        <el-table-column prop="card_name" label="卡券名称" min-width="150" fixed="left" show-overflow-tooltip />
        <el-table-column prop="card_value" label="卡券面值/价位" min-width="110" />
        <el-table-column prop="shop_name" label="所属商城" min-width="150" show-overflow-tooltip />
        <el-table-column prop="card_num" :label="activeName === '1' ? '需开卡数量' : '需退卡数量'" min-width="120" />
        <el-table-column prop="app_type_shop_type_flag" label="应用类型" min-width="100">
          <template slot-scope="{ row }">
            {{ getAppTypeName(row.app_type_shop_type_flag) }}
          </template>
        </el-table-column>
        <el-table-column v-if="activeName === '1'" prop="status" label="激活状态" min-width="80">
          <template slot-scope="{ row }">
            <span v-if="row.status == 0">未激活</span>
            <span v-if="row.status == 2">已激活</span>
          </template>
        </el-table-column>
        <el-table-column v-if="activeName === '2'" prop="status" label="禁用状态" min-width="80">
          <template slot-scope="{ row }">
            <span v-if="row.status == 0">未禁用</span>
            <span v-if="row.status == 2">已禁用</span>
          </template>
        </el-table-column>
        <el-table-column prop="client_name" label="客户名称" min-width="150" show-overflow-tooltip />
        <el-table-column prop="link_name" label="联系人名称" min-width="100" show-overflow-tooltip />
        <el-table-column prop="link_phone" show-overflow-tooltip label="联系电话" min-width="120" />
        <el-table-column prop="sell_name" label="销售人员名称" min-width="120" show-overflow-tooltip />
        <el-table-column prop="create_time" label="订单创建时间" min-width="150">
          <template slot-scope="{ row }">{{ row.create_time | unixToDate }}</template>
        </el-table-column>
        <el-table-column prop="trade_source" label="单据来源" min-width="110">
          <template slot-scope="{ row }">
            <span v-if="row.trade_source == 2">移动端前台</span>
            <span v-else>企业后台</span>
          </template>
        </el-table-column>
        <el-table-column prop="trade_status" label="单据状态" min-width="80">
          <template slot-scope="{ row }">
            {{ getTradeTypeName(row.trade_status) }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="130" fixed="right">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="handleOperateOrder(scope.row)">查看详情</el-button>
          </template>
        </el-table-column>
      </template>
      <template v-else slot="table-columns">
        <el-table-column key="selection2" type="selection" :reserve-selection="true" fixed />
        <el-table-column key="recharge_sn" prop="sn" :label="activeName === '3' ? '充值销售单号' : '充值退款单号'" min-width="200"
          fixed="left"></el-table-column>
        <el-table-column key="trade_source" prop="trade_source" label="单据来源" min-width="150" fixed="left"
          show-overflow-tooltip>
          <template slot-scope="{ row }">
            {{ row.trade_source == 1 ? '企业后台' : '移动端前台' }}
          </template>
        </el-table-column>
        <el-table-column key="recharge_number" prop="recharge_number" :label="activeName === '3' ? '充值人数' : '扣款人数'"
          min-width="200" />
        <el-table-column key="discounts_total_money" prop="discounts_total_money"
          :label="activeName === '3' ? '应收金额' : '应退金额'" min-width="200" />
        <el-table-column key="shop_type" prop="shop_type" label="来源应用" min-width="150" show-overflow-tooltip>
          <template slot-scope="{ row }">
            {{ row.shop_type == 1 ? '现金商城' : '双选商城' }}
          </template>
        </el-table-column>
        <el-table-column prop="shop_name" label="所属商城" min-width="150" show-overflow-tooltip />
        <el-table-column prop="customer_name" label="客户名称" min-width="150" show-overflow-tooltip />
        <el-table-column prop="contacts_person" label="联系人名称" min-width="100" show-overflow-tooltip />
        <el-table-column prop="contacts_phone" show-overflow-tooltip label="联系电话" min-width="120" />
        <el-table-column prop="seller_name" label="销售人员名称" min-width="120" show-overflow-tooltip />
        <el-table-column prop="trade_status" label="单据状态" min-width="80">
          <template slot-scope="{ row }">
            {{ getTradeTypeName(row.trade_status) }}
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="单据日期" min-width="150">
          <template slot-scope="{ row }">{{ row.create_time | unixToDate }}</template>
        </el-table-column>
        <el-table-column prop="remark" label="备注信息" min-width="150" show-overflow-tooltip />
        <el-table-column label="操作" width="80" fixed="right">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="handleOperateOrder(scope.row)">查看</el-button>
          </template>
        </el-table-column>
      </template>
      <el-pagination slot="pagination" @size-change="handlePageSizeChange" @current-change="handlePageCurrentChange"
        :current-page="pageData.page_no" :page-size="pageData.page_size" :page-sizes="MixinPageSizes"
        :layout="MixinTableLayout" background :total="pageData.data_total"></el-pagination>
    </en-table-layout>
  </div>
</template>

<script>
import * as API_order from "@/api/order";
import { handleDownload } from "@/utils";
import { Foundation } from "@/../ui-utils";
import EnTableLayout from "../../../ui-components/TableLayout/src/main";
import mixins from "@/views/tools/sale-card-open/modules/saleListMixins";
import { getShopExtAuth } from '@/api/order';
import { getShopLv } from '@/api/shop';
export default {
  name: "couponOrderList",
  mixins: [mixins],
  components: {
    EnTableLayout,
  },

  data () {
    return {
      double_choice_mall_open: true,//是否开启双选商城
      has_intelligent_card20: true,//是否开启智能套餐卡商城
      counting_card_open: true,//是否开启小程序商城
      has_cash_mall: true, //是否开启现金商城
      isOpen: false,
      activeName: "1",
      // 列表loading状态
      loading: false,
      // 导出loading状态
      importLoading: false,
      importLoading1: false,
      // 页面表格
      tableData: {},
      // 列表选中数据
      tempList: [],
      // 列表参数
      params: {
        page_no: 1,
        page_size: 20
      },
      // 列表分页数据
      pageData: [],
      // 搜索数据
      advancedForm: {
        type: "sell_trade_sn",
        trade_status: "",
        status: "",
        app_type_shop_type_flag: "",
      },
      typeOptions: [],
      shopTypeOption: [
        { label: '现金商城', value: 1 },
        { label: '双选商城', value: 2 }
      ],
      //充值导出表头
      rechargeHeader: ['充值销售单号', '单据来源', '充值人数', '应收金额', '来源应用', '所属商城', '客户名称', '联系人名称', '联系电话', '销售人员名称', '单据状态', '单据日期', '备注信息'],
      rechargeKeys: ['sn', 'trade_source', 'recharge_number', 'discounts_total_money', 'shop_type', 'shop_name', 'customer_name', 'contacts_person', 'contacts_phone', 'seller_name', 'trade_status', 'create_time', 'remark'],
      //退款导出表头
      refundHeader: ['充值退款单号', '单据来源', '扣款人数', '应退金额', '来源应用', '所属商城', '客户名称', '联系人名称', '联系电话', '销售人员名称', '单据状态', '单据日期', '备注信息'],
    };
  },
  computed: {
    newtradeType () {
      return ['3', '4'].includes(this.activeName) ? this.tradeType.filter(item => item.value !== 1) : this.tradeType
    }
  },
  async mounted () {
    console.log('mounted');
    //判断大平台开关
    const doubledata = await getShopExtAuth();
    const aicard20 = await getShopLv();
    this.$store.commit("DEL_VISITED_VIEWS", this.$route);
    this.double_choice_mall_open = doubledata.double_choice_mall_open == "OPEN"
    this.has_cash_mall = aicard20.data.has_cash_mall == 1
    this.has_intelligent_card20 = aicard20.data.has_intelligent_card20 == 1
    this.counting_card_open = doubledata.counting_card_open == "OPEN"
    if (sessionStorage.getItem("activeName")) {
      this.activeName = sessionStorage.getItem("activeName");
    }
    this.typeOptions = this.cardTypes;
    // 获取当前企业所拥有的类型(卡券订单)
    this.filtersCards(1)
    // 获取当前企业所拥有的类型(充值单)
    // 获取当前企业所拥有的类型(卡券订单)
    API_order.getShopType().then(res => {
      if (!this.double_choice_mall_open) {
        if (res.includes(2) == false) {
          this.shopTypeOption = this.shopTypeOption.filter(item => item.label != '双选商城')
        }
        if (res.includes(1) == false) {
          this.shopTypeOption = this.shopTypeOption.filter(item => item.label != '现金商城')
        }
      }
    })

    // this.shopTypeOption =
    // 判断企业跳站点
    // if (sessionStorage.getItem("MixinIsFormEnterprise")) {
    //   this.MixinIsFormEnterprise = JSON.parse(
    //     sessionStorage.getItem("MixinIsFormEnterprise")
    //   );
    // }
    // API_Login.getLoginType().then(res => {
    //   if (res && res.parentLogin === 1) {
    //     this.MixinIsFormEnterprise = true;
    //     sessionStorage.setItem("MixinIsFormEnterprise", true);
    //   } else sessionStorage.setItem("MixinIsFormEnterprise", false);
    // });
    // console.log(this.MixinIsFormEnterprise);
    // this.params.shopType = this.MixinIsFormEnterprise ? 0 : 1;
    // 获取订单列表
    this.GET_OrderList(this.params);
  },
  destroyed () {
    sessionStorage.removeItem("activeName");
  },
  activated () {
    console.log('activated');
    // 获取订单列表
    this.GET_OrderList(this.params);
    this.$store.commit("DEL_VISITED_VIEWS", this.$route);
  },
  watch: {
    activeName: {
      immediate: true,
      handler (n, o) {
        this.pageData.data = [];
        this.params = {
          page_no: 1,
          page_size: 20
        };
        this.tempList = [];
        this.advancedForm = {
          // 时间
          order_time_range: [],
          // 激活/禁用状态
          status: "",
          // 单据状态
          trade_status: "",
          // 关键字
          keywords: "",
          type: this.type_filter(),
          app_type_shop_type_flag: "",
          shop_type: "",
          trade_source: ''
        };

        // this.$refs.enTableLayout.clearSelected();
        this.GET_OrderList(this.params);
        if (this.activeName == '1') {
          // 获取当前企业所拥有的类型(卡券订单)
          this.filtersCards(1)
        } else if (this.activeName == '2') {
          // 获取当前企业所拥有的类型(卡券订单)
          this.filtersCards(2)
        }
      }
    }
  },
  methods: {
    PermissionToCheck () {
      return API_order.getShopExtAuth().then((res) => {
        if (res.open !== "OPEN") {
          this.$message.error(
            "平台已关闭销售开卡功能，如有其他问题，请联系平台"
          );
          setTimeout(() => {
            this.$store.getters.permission_routers.forEach((item) => {
              if (item.path === "/order") {
                // 关闭菜单
                item.children.forEach((value, index) => {
                  if (value.path === "coupon-order-list") {
                    item.children.splice(index, 1);
                    this.$router.push("/order/order-lista");
                  }
                });
              }
            });
          }, 3000);
        } else {
          return true;
        }
      });
    },
    getRowKeys (row) {
      return row.id;
    },
    filtersCards (type) {
      // 获取当前企业所拥有的类型(卡券订单)
      API_order.getShopTypeTwo({ type: type }).then(res => {
        if (!this.double_choice_mall_open) {
          if (res.includes(2) == false) {
            this.typeOptions = this.typeOptions.filter(item => item.label != '双选储值卡')
          }
        }
        if (!this.has_cash_mall) {
          if (res.includes(1) == false) {
            this.typeOptions = this.typeOptions.filter(item => item.label != '现金储值卡')
          }
        }
        if (!this.has_intelligent_card20) {
          if (res.includes(3) == false) {
            this.typeOptions = this.typeOptions.filter(item => item.label != 'AI智能套餐卡')
          }
        }
        if (!this.counting_card_open) {
          if (res.includes(4, 5) == false) {
            this.typeOptions = this.typeOptions.filter(item => item.label != '计次卡')
            this.typeOptions = this.typeOptions.filter(item => item.label != '宅配卡')
          }
        }
      })
    },
    // 页签选择
    setType (el) {

    },
    type_filter () {
      switch (this.activeName) {
        case '1':
          return "sell_trade_sn";
        case '2':
          return "sell_trade_sn";
        case '3':
          return "recharge_sn";
        case '4':
          return "recharge_sn";
        default:
          return "";
      }
    },
    // 选中数据
    orderListSelectionChange (list) {
      this.tempList = list;
    },
    // 查看详情
    handleOperateOrder (row) {
      this.$store.dispatch("addVisitedViews", this.$route);
      const id = ['1', '2'].includes(this.activeName) ? row.id : row.sn
      this.PermissionToCheck().then((isRes) => {
        if (!isRes) return;
        this.$router.push({
          name: "couponOrderDetail",
          params: {
            id: id,
          },
          query: {
            type: this.activeName
          }
        });
        // this.$router.push({
        //   path: `/order/coupon-order-detail/${id}?type=${this.activeName}`,
        // });
      });
    },
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.GET_OrderList(this.params);
    },
    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.GET_OrderList(this.params);
    },
    /** 高级搜索事件触发 */
    advancedSearchEvent () {
      this.params.page_no = 1;
      let params = {
        ...this.params,
        ...this.advancedForm,
      };
      params[this.advancedForm.type] = params.keywords

      if (this.advancedForm.order_time_range && this.advancedForm.order_time_range.length > 1) {
        params.start_time =
          this.advancedForm.order_time_range[0].getTime() / 1000;
        params.end_time =
          this.advancedForm.order_time_range[1].getTime() / 1000 + 86400;
      }
      delete params.keywords;
      delete params.type;
      delete params.order_time_range;
      for (const key in params) {
        if (params[key] === "") {
          delete params[key];
        }
      }
      this.GET_OrderList(params);
    },
    remove (arr, str) {
      var index = arr.indexOf(str);
      return arr.splice(index, 1);
    },
    //导出
    submitImport (type) {
      this.PermissionToCheck().then((isRes) => {
        if (!isRes) return;
        let params = { ...this.params };
        if (type == 1) {
          params.ids = this.tempList.map((g) => {
            return g.id;
          });
          if (!params.ids.length)
            return this.$message.error("请先选择要导出的数据");
        }
        if (type == 0) {
          this.importLoading = true;
        } else {
          this.importLoading1 = true;
        }
        let orderFunc = "getCardsOrderList";

        let filterVals = [
          "sell_trade_sn",
          "card_name",
          "card_value",
          "shop_name",
          "card_num",
          "app_type_shop_type_flag",
          "status",
          "client_name",
          "link_name",
          "link_phone",
          "sell_name",
          "create_time",
          "trade_source",
          "trade_status",
        ];
        let tHeaders = [
          "销售单据编号",
          "销售退单编号",
          "卡券名称",
          "卡券面值/价位",
          "所属商城",
          "需开卡数量",
          "需退卡数量",
          "应用类型",
          "激活状态",
          "禁用状态",
          "客户名称",
          "联系人名称",
          "联系电话",
          "销售人员名称",
          "订单创建时间",
          "单据来源",
          "单据状态",
        ];
        let templateName = "";
        switch (this.activeName) {
          case "1":
            templateName = "卡券订单列表";
            this.remove(tHeaders, "销售退单编号");
            this.remove(tHeaders, "需退卡数量");
            this.remove(tHeaders, "禁用状态");
            delete params.page_no;
            delete params.page_size;
            break;
          case "2":
            templateName = "卡券退单列表";
            orderFunc = "getRefundOrderList"
            this.remove(tHeaders, "销售单据编号");
            this.remove(tHeaders, "需开卡数量");
            this.remove(tHeaders, "激活状态");
            delete params.page_no;
            delete params.page_size;
            break;
          case "3":
            templateName = "充值订单列表";
            orderFunc = "getCardsSellList"
            params.trade_type = 1;
            params.query_type = 1;
            tHeaders = this.rechargeHeader;
            filterVals = this.rechargeKeys;
            params.page_no = 1;
            params.page_size = 99999;
            params.recharge_ids = params.ids;
            delete params.ids;
            break;
          case "4":
            templateName = "充值退款单列表";
            orderFunc = "getCardsSellList"
            params.trade_type = 2;
            params.query_type = 1;
            tHeaders = this.refundHeader;
            filterVals = this.rechargeKeys;
            params.page_no = 1;
            params.page_size = 99999;
            params.recharge_ids = params.ids;
            delete params.ids;
            break;
          default:
            break;
        }
        API_order[orderFunc](params)
          .then((res) => {
            res.data.forEach((item, index) => {
              item.create_time = Foundation.unixToDate(
                item.create_time,
                "yyyy-MM-dd hh:mm:ss"
              );
              switch (item.status) {
                case 0:
                  item.status = this.activeName === "1" ? "未激活" : "未禁用";
                  break;
                case 2:
                  item.status = this.activeName === "1" ? "已激活" : "已禁用";
                  break;
                default:
                  break;
              }
              item.trade_status = this.getTradeTypeName(item.trade_status)
              item.app_type_shop_type_flag = this.getAppTypeName(item.app_type_shop_type_flag)
              item.trade_source = item.trade_source == 1 ? '企业后台' : '移动端前台';
              item.shop_type = item.shop_type == 1 ? '现金商城' : '双选商城';
            });
            console.log(res.data);
            handleDownload(
              res.data,
              tHeaders,
              filterVals,
              templateName,
            );
            this.importLoading = false;
            this.importLoading1 = false;
          })
          .catch((res) => {
            this.importLoading = false;
            this.importLoading1 = false;
          });
      });
    },
    GET_OrderList (params) {
      this.PermissionToCheck().then((isRes) => {
        if (!isRes) return;
        this.loading = true;
        if (this.activeName === "1") {//卡券订单
          API_order.getCardsOrderList(params).then((res) => {
            this.pageData = res;
          });
        } else if (this.activeName === "2") {//卡券退单
          API_order.getRefundOrderList(params).then((res) => {
            this.pageData = res;
          });
        }
        else if (this.activeName === "3") {//充值
          params.trade_type = 1;
          params.query_type = 1;
          API_order.getCardsSellList(params).then((res) => {
            this.pageData = res;
          });
        }
        else if (this.activeName === "4") {//退款
          params.trade_type = 2;
          params.query_type = 1;
          API_order.getCardsSellList(params).then((res) => {
            this.pageData = res;
          });
        }
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-tabs__header {
  margin: 0;
}
</style>
